import React, { useMemo } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend);

interface ChartComponentProps {
  priceHistory: { time: string; price: number }[];
  timeframe: string;
}

const ChartComponent: React.FC<ChartComponentProps> = ({ priceHistory, timeframe }) => {
  const chartData = useMemo(() => ({
    labels: priceHistory.map((point) => point.time),
    datasets: [
      {
        label: 'Price (USD)',
        data: priceHistory.map((point) => point.price),
        borderColor: 'rgba(0, 255, 220, 1)',
        backgroundColor: 'rgba(0, 255, 220, 0.2)',
        pointRadius: 3,
        pointHoverRadius: 6,
        tension: 0.3,
      },
    ],
  }), [priceHistory]);

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        labels: {
          color: 'rgba(0, 255, 220, 1)',
        },
      },
    },
  };

  return (
    <div className="bg-gray-800 rounded-md mb-6" style={{ height: '300px' }}>
      <Line data={chartData} options={chartOptions} />
    </div>
  );
};

export default ChartComponent;
