import React from "react";

interface FAQProps {
    onScrollToTelegram: () => void;
  }

  const FAQ: React.FC<FAQProps> = ({ onScrollToTelegram }) => {
    return (
      <section className="p-8 bg-gray-900 text-white">
        <div className="space-y-6 max-w-4xl mx-auto">

          <div className="bg-gray-800 rounded-lg p-6 shadow-md">
            <h3 className="text-lg sm:text-xl font-semibold">Contract address</h3>
            <p className="text-gray-300 mt-2 break-words">
              FJsU8cSyKUgHwJgoB2LpuB9G3m7V47wfNwESndyWpump
            </p>
            <a
              href="https://solscan.io/token/FJsU8cSyKUgHwJgoB2LpuB9G3m7V47wfNwESndyWpump"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-400 underline hover:text-blue-600 transition duration-300 break-words"
            >
              Solscan
            </a>
          </div>

          <div className="bg-gray-800 rounded-lg p-6 shadow-md">
            <h3 className="text-lg sm:text-xl font-semibold">Tokenomics</h3>
            <p className="text-gray-300 mt-2 break-words">
                The Honest Dev ($DEV) ensures transparency and efficiency in its token allocation:
                <br />
                - <span className="font-bold">14% distributed equally across major exchanges</span> (2% each):
                Binance, Coinbase, Kraken, Bybit, and Okex.
                <br />
                - <span className="font-bold">Supply</span>: 1 Billion ($DEV).
                <br />
                - <span className="font-bold">Burn Mechanism</span>: 1.33% burned to promote scarcity.
                <br />
                - <span className="font-bold">Liquidity Pool</span>: The remaining supply is locked into liquidity to ensure stability and growth.
            </p>
            </div>

          <div className="bg-gray-800 rounded-lg p-6 shadow-md">
            <h3 className="text-lg sm:text-xl font-semibold">How can I join the community?</h3>
            <p className="text-gray-300 mt-2">
              Currently, we have 500+ active members on our Telegram, and growing!{" "}
              <button
                onClick={onScrollToTelegram}
                className="text-blue-400 underline hover:text-blue-600 transition duration-300"
              >
                Check out links at the top of our page
              </button>
            </p>
          </div>
        </div>
      </section>
    );
  };
  

export default FAQ;
