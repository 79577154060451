import React, { useState } from 'react';
import ChartComponent from './components/charts/ChartComponent.tsx';
import PriceFetcher from './tools/charthelpers/PriceFetcher.tsx';
import BrandIcon from './imgs/400x400.jpg';
import BrandBanner from './imgs/1500x500.jpg';
import FAQ from './components/FAQ.tsx';

import { TokenIcon, WalletIcon } from '@web3icons/react'

const App: React.FC = () => {
  const [priceHistory, setPriceHistory] = React.useState<{ time: string; price: number }[]>([]);
  const [timeframe, setTimeframe] = React.useState('1m');
  const [showGeckoTerminal, setShowGeckoTerminal] = useState(true); // Default to GeckoTerminal chart
  const [menuOpen, setMenuOpen] = useState(false);
  const handleTimeframeChange = (newTimeframe: string) => {
    setTimeframe(newTimeframe);
  };


  const scrollToTelegram = () => {
    const telegramLink = document.getElementById("telegram-link");

    // Open the menu if it's closed
    if (!menuOpen) {
      setMenuOpen(true);
    }

    setTimeout(() => {
      if (telegramLink) {
        // Scroll to the Telegram link
        telegramLink.scrollIntoView({ behavior: "smooth", block: "center" });

        // Highlight the link temporarily
        telegramLink.classList.add("bg-red-500", "text-white");
        setTimeout(() => {
          telegramLink.classList.remove("bg-red-500", "text-white");
        }, 2000); // Remove the highlight after 2 seconds
      }
    }, 200); // Delay to ensure the menu has opened
  };

  return (
    <div className="bg-gray-900 text-gray-200 min-h-screen flex flex-col">
    <header className="p-4 bg-gray-800 flex justify-between items-center shadow-lg relative">
      {/* Left: Brand Icon */}
      <div className="flex items-center space-x-2">
        <img
          src={BrandIcon}
          alt="Brand Icon"
          className="w-10 h-10 rounded-full border border-red-600"
        />
        <span className="block md:hidden text-xl font-bold text-red-600">$DEV</span>
        <span className="hidden md:block text-xl font-bold text-red-600">The Honest Dev (DEV)</span>
      </div>

      {/* Hamburger Menu Button */}
      <button
        onClick={() => setMenuOpen((prev) => !prev)}
        className="md:hidden text-gray-300 focus:outline-none"
        aria-label="Toggle navigation menu"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          {menuOpen ? (
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          ) : (
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 6h16M4 12h16M4 18h16"
            />
          )}
        </svg>
      </button>

      {/* Right: Socials */}
      <nav
  className={`${
    menuOpen ? "block" : "hidden"
  } md:flex absolute md:static top-16 left-0 w-full md:w-auto bg-gray-800 md:bg-transparent shadow-lg md:shadow-none p-4 md:p-0 z-50`}
>
  <div className="flex flex-wrap md:flex-nowrap justify-evenly md:justify-between gap-3 md:gap-4 w-full">
    <a
      href="https://x.com/TheHonest_Dev"
      target="_blank"
      rel="noopener noreferrer"
      className="hover:text-gray-400 text-gray-300 text-center w-full md:w-auto"
    >
      X
    </a>
    <a
      id="telegram-link"
      href="https://t.me/The_Honest_Dev"
      target="_blank"
      rel="noopener noreferrer"
      className="hover:text-gray-400 text-gray-300 text-center w-full md:w-auto"
    >
      Telegram
    </a>
    <a
      href="https://pump.fun/coin/FJsU8cSyKUgHwJgoB2LpuB9G3m7V47wfNwESndyWpump"
      target="_blank"
      rel="noopener noreferrer"
      className="hover:text-gray-400 text-gray-300 text-center w-full md:w-auto"
    >
      Pump.fun
    </a>
    <a
      href="https://www.geckoterminal.com/solana/pools/5y8EfhBiwK2Yrzdoer7Au21F1ZpU7YoDyBNrrPmPk1FE"
      target="_blank"
      rel="noopener noreferrer"
      className="hover:text-gray-400 text-gray-300 text-center w-full md:w-auto"
    >
      Raydium
    </a>
  </div>
</nav>


    </header>

      {/* Banner Section */}
      <section
      className="h-48 md:h-64 lg:h-80 bg-black flex justify-center items-center shadow-lg"
      style={{
        backgroundImage: `url(${BrandBanner})`,
        backgroundSize: "contain", 
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
      ></section>



      {/* Token Presentation */}
      <section className="p-8 bg-gray-800 text-center shadow-md">
        <h2 className="text-3xl font-extrabold text-red-600 mb-4">About The Honest Dev (DEV)</h2>
        <div className="text-lg leading-relaxed max-w-2xl mx-auto text-gray-300 text-center italic border-l-4 border-red-600 pl-4">
        <p>
          "Last night I had a dream, I dreamed of a world where everyone trusted each other, loved and
          supported each other, a world without hate and disappointment, full of honesty, a world filled
          with Devs."
        </p>
      </div>
      </section>

      <section className="p-6 bg-gray-900 text-white text-center shadow-md">
  <h2 className="text-2xl sm:text-3xl font-bold mb-4">14% Allocated to Major Exchanges</h2>
  <p className="text-lg sm:text-xl text-gray-300 max-w-4xl mx-auto leading-relaxed">
    <span className="font-bold text-red-500">14%</span> of the total supply is distributed equally across major exchanges:
  </p>
  <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 gap-4 mt-4">
    <div className="flex flex-col items-center">
      <TokenIcon symbol="bnb" variant="branded" className="w-8 h-8 sm:w-10 sm:h-10" />
      <span className="text-gray-300 text-sm sm:text-base mt-2">Binance</span>
    </div>
    <div className="flex flex-col items-center">
      <WalletIcon id="coinbase" variant="branded" className="w-8 h-8 sm:w-10 sm:h-10" />
      <span className="text-gray-300 text-sm sm:text-base mt-2">Coinbase</span>
    </div>
    <div className="flex flex-col items-center">
      <WalletIcon id="kraken wallet" variant="branded" className="w-8 h-8 sm:w-10 sm:h-10" />
      <span className="text-gray-300 text-sm sm:text-base mt-2">Kraken</span>
    </div>
    <div className="flex flex-col items-center">
      <TokenIcon symbol="btc" variant="branded" className="w-8 h-8 sm:w-10 sm:h-10" />
      <span className="text-gray-300 text-sm sm:text-base mt-2">Bybit</span>
    </div>
    <div className="flex flex-col items-center">
      <WalletIcon id="okx" variant="mono" className="w-8 h-8 sm:w-10 sm:h-10" />
      <span className="text-gray-300 text-sm sm:text-base mt-2">Okex</span>
    </div>
  </div>
</section>



        {/* Chart Toggle Buttons */}
      <div className="p-4 bg-gray-900 flex justify-center items-center space-x-4">
    <button
      onClick={() => setShowGeckoTerminal(true)}
      className={` w-40 text-center px-4 py-2 rounded-md ${
        showGeckoTerminal
          ? 'bg-red-500 text-white' // Softer red for active state
          : 'bg-gray-800 text-gray-300 hover:bg-red-500 hover:text-white' // Softer hover effect
      } transition duration-300`}
    >
      GeckoTerminal Chart
    </button>
    <button
      className={` w-40 text-center px-4 py-2 rounded-md bg-gray-800 text-gray-300 cursor-not-allowed`}
    >
     COMING SOON🚀
    </button>
  </div>


      {/* Chart Section */}
      <div className="flex-grow p-4 bg-gray-800">
        {showGeckoTerminal ? (
          // GeckoTerminal Embed with Scrollable Container
          <div
            className="relative overflow-auto max-h-[75vh] border border-gray-700 rounded-md"
            style={{ scrollbarWidth: 'thin', WebkitOverflowScrolling: 'touch' }}
          >
            <iframe
              height="100%"
              width="100%"
              id="geckoterminal-embed"
              title="GeckoTerminal Embed"
              src="https://www.geckoterminal.com/solana/pools/5y8EfhBiwK2Yrzdoer7Au21F1ZpU7YoDyBNrrPmPk1FE?embed=1&info=1&swaps=1&grayscale=0&light_chart=0"
              frameBorder="0"
              allow="clipboard-write"
              allowFullScreen
              className="w-full h-[500px] rounded-md shadow-lg"
            ></iframe>
          </div>
        ) : (
          // Custom Chart
          <>
            <div className="flex flex-wrap justify-center gap-2 p-4 bg-gray-900 shadow-md">
              {['1m', '5m', '15m', '1h', '1d'].map((frame) => (
                <button
                  key={frame}
                  onClick={() => handleTimeframeChange(frame)}
                  className={`px-4 py-2 rounded-md w-20 text-center ${
                    timeframe === frame
                      ? 'bg-red-500 text-white'
                      : 'bg-gray-800 text-gray-300 hover:bg-red-500 hover:text-white'
                  } transition duration-300`}
                >
                  {frame.toUpperCase()}
                </button>
              ))}
            </div>

            <ChartComponent priceHistory={priceHistory} timeframe={timeframe} />
            <PriceFetcher timeframe={timeframe} onDataUpdate={setPriceHistory} />
          </>
        )}
      </div>

      {/* FAQ cuz the people gotta know yo */}
      <FAQ onScrollToTelegram={scrollToTelegram} />


      {/* Footer */}
      <footer className="p-4 bg-gray-800 text-center shadow-lg text-gray-300">
      <p>© 2024 The Honest Dev (DEV). All rights reserved.</p>
      <div className="flex justify-center items-center space-x-2 mt-2 text-sm">
      <span>Website by</span>
      <a
      href="https://x.com/nicewojak"
      target="_blank"
      rel="noopener noreferrer"
      className="text-red-500 hover:text-red-400 transition duration-300"
    >
      @nicewojak
    </a>
    <span>💻</span>
      </div>
      </footer>
    </div>



  );
};

export default App;
