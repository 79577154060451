import React, { useState, useEffect } from 'react';

interface PriceFetcherProps {
  timeframe: string;
  onDataUpdate: (priceHistory: { time: string; price: number }[]) => void;
}

const PriceFetcher: React.FC<PriceFetcherProps> = ({ timeframe, onDataUpdate }) => {
  const fetchHistoricalOHLCV = async (timeframe: string) => {
    try {
      let endpoint;
      switch (timeframe) {
        case '1m':
          endpoint = 'minute?aggregate=1';
          break;
        case '5m':
          endpoint = 'minute?aggregate=5';
          break;
        case '15m':
          endpoint = 'minute?aggregate=15';
          break;
        case '1h':
          endpoint = 'hour?aggregate=1';
          break;
        case '1d':
          endpoint = 'day?aggregate=1';
          break;
        default:
          endpoint = 'minute?aggregate=1';
      }

      const apiUrl = `https://api.geckoterminal.com/api/v2/networks/solana/pools/5y8EfhBiwK2Yrzdoer7Au21F1ZpU7YoDyBNrrPmPk1FE/ohlcv/${endpoint}`;
      console.log(`Fetching OHLCV Data: ${apiUrl}`);

      const response = await fetch(apiUrl);
      if (!response.ok) {
        throw new Error(`API call failed with status ${response.status}`);
      }

      const data = await response.json();

      const historicalData = data.data.attributes.ohlcv_list.map((candle: any[]) => ({
        time: new Date(candle[0] * 1000).toLocaleTimeString(),
        price: candle[4],
      }));

      onDataUpdate(historicalData); // Pass the fetched data to the parent
    } catch (error) {
      console.error('Error fetching OHLCV data:', error.message);
    }
  };

  useEffect(() => {
    fetchHistoricalOHLCV(timeframe);
  }, [timeframe]);

  return null; // This component has no UI, it only fetches data
};

export default PriceFetcher;
